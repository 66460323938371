export const Tuples = [
    [
        {
            "words": [
                "उकळणे",
                " पेय",
                " दार्जिलिंग",
                " पाने "
            ],
            "theme": " चहा",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "टेकडी",
                " मंदिर",
                " पायऱ्या",
                " पुणे"
            ],
            "theme": "पुणे स्थित  पर्वती टेकडी संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "भरती",
                " लाटा",
                " गाज",
                " पुळण "
            ],
            "theme": " समुद्र",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मृगजळ",
                " उंट",
                " निवडुंग",
                " उष्ण "
            ],
            "theme": " वाळवंट ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "कमला",
                " शांतता",
                " सखाराम",
                " कोतवाल"
            ],
            "theme": "विजय तेंडुलकर यांच्या नाटकांच्या  नावातील शब्द",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "भांगडा",
                " गरबा",
                " बिहू",
                " घूमर "
            ],
            "theme": " विविध नृत्य प्रकार",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "इंदिरा संत",
                " शांता शेळके",
                " कुसुमाग्रज",
                " केशवसुत"
            ],
            "theme": " प्रसिद्ध मराठी कवी/कवयित्री",
            "sharedBy": "शब्दबंध टीम",
            "difficulty": 0
        },
        {
            "words": [
                "वि. स. खांडेकर",
                " शिवाजी सावंत",
                " रणजीत देसाई",
                " ना. सी. फडके"
            ],
            "theme": " प्रसिद्ध मराठी कादंबरीकार",
            "sharedBy": "शब्दबंध टीम",
            "difficulty": 1
        },
        {
            "words": [
                "अण्णासाहेब किर्लोस्कर",
                " मधुसूदन कालेलकर",
                " वसंत कानेटकर",
                " विद्याधर गोखले"
            ],
            "theme": " प्रसिद्ध मराठी नाटककार",
            "sharedBy": "शब्दबंध टीम",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "इथिओपिया",
                "बरंच काही",
                "चिकोरी",
                "अरॅबिका"
            ],
            "theme": "कॉफी - त्याचे जन्मस्थान, शास्त्रीय नाव, मिश्रण, चित्रपट इत्यादी",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "इराणी",
                " बासुंदी",
                "तंदुरी",
                " गवती"
            ],
            "theme": " चहाचे प्रकार ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "चहा",
                "कागद",
                "दारू",
                "नूडल्स/शेवया"
            ],
            "theme": "चीनचे प्राचीन शोध",
            "sharedBy": "शब्दबंध टीम",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कादंबरी",
                " नाटक",
                " कविता",
                " चरित्र"
            ],
            "theme": "वाङ्मयीन प्रकार  ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "बुद्धिबळ",
                " संगीत",
                " ओरिगामी",
                " चित्रकला "
            ],
            "theme": " छंद",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "⁠बाग",
                " पुणे",
                " गणपती",
                " तळे"
            ],
            "theme": "पुणे स्थित सारसबाग संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सर्कस",
                " फौजी",
                " उम्मीद",
                " दूसरा केवल"
            ],
            "theme": "शाहरुख खान यांच्या दूरदर्शनवरील मालिका ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "पैंजण",
                " ढोलकी",
                " बैठक",
                " नटरंग "
            ],
            "theme": " लावणीशी संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "आकाश",
                " खंड्या",
                " गोकर्ण",
                " नीलम"
            ],
            "theme": "निळ्या गोष्टी ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मैदान ",
                " हार ",
                "जीत",
                " पंच "
            ],
            "theme": " मैदानी खेळ ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 0
        },
        {
            "words": [
                "उकळणे",
                "टॅनीन",
                " दार्जिलिंग",
                " पाने "
            ],
            "theme": " चहा",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "भरती",
                " लाटा",
                " गाज",
                " पुळण "
            ],
            "theme": " समुद्र",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "गाठ",
                " राखी",
                " तंबू ",
                " फास"
            ],
            "theme": "दोरी/दोरखंड इत्यादींशी संबंधित ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "लोकल",
                " डबेवाले",
                " व्यापार",
                " बंदर"
            ],
            "theme": "मुंबई",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "मशाल",
                "इंजिन",
                "कमळ",
                "घड्याळ"
            ],
            "theme": "महाराष्ट्रातील पक्षांची निवडणूक चिन्हे",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कांगारू",
                " कोआला",
                " उलुरु ",
                " ब्रॅडमन "
            ],
            "theme": " ऑस्ट्रेलिया",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "ज्योत",
                " वाढदिवस",
                " दिवाळी",
                " मेण"
            ],
            "theme": " मेणबत्तीशी संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "नंदी",
                "रेडा",
                "उंदीर",
                "गरुड"
            ],
            "theme": "काही देवांची वाहने ",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मुघल",
                " गड ",
                " मराठा",
                " राज्याभिषेक "
            ],
            "theme": " ​​शिवाजी महाराज ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "गाजर",
                " आले",
                " हळद",
                " वसाबी "
            ],
            "theme": " जमिनीखालच्या खाण्यायोग्य वनस्पती ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "कोन",
                " स्कूप",
                " दूध",
                " व्हॅनिला "
            ],
            "theme": " आईस्क्रीम ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "काळजी",
                "विश्रांती",
                "झेप",
                "श्वास"
            ],
            "theme": "ज्या गोष्टी घेता येतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "चित्रं",
                "झोप",
                "खोडी",
                "पाणी"
            ],
            "theme": "ज्या गोष्टी काढता येतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "शोध",
                "विरजण",
                "झाड",
                "आग"
            ],
            "theme": "ज्या गोष्टी लावता येतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कॅडेट्स",
                " शिस्त",
                " परेड",
                " शौर्य"
            ],
            "theme": " भारतीय संरक्षण प्रबोधिनी (NDA) ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "रक्त",
                " जास्वंद",
                " माणिक",
                " सफरचंद"
            ],
            "theme": "लाल रंगाच्या वस्तू",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "भांगडा",
                " सलवारखमीज",
                " गहू",
                " हॉकी"
            ],
            "theme": " पंजाबशी संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "ढाल",
                "करंडक",
                "पदक",
                "चक्र"
            ],
            "theme": "पारितोषिके",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "मेण",
                "आइस्क्रीम",
                "मीठ",
                "हृदय"
            ],
            "theme": "विरघळणार्या / पाघळणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "समुद्र",
                "पोफळी",
                "मोदक",
                "आंबा"
            ],
            "theme": "कोकणातील गोष्टी ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कविता",
                " हुजूरपागा",
                "आनंदाचे झाड",
                "ऋतू हिरवा"
            ],
            "theme": "कवयित्री शांता शेळके यांच्याशी संबंधित",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "हात",
                "चौकट",
                "हुकूम",
                "विदुषक"
            ],
            "theme": "पत्ते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "गाऊन",
                "तराजू",
                "सर्वोच्च",
                "कायदा"
            ],
            "theme": "न्यायालय",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सागरा धरणी",
                "संधी",
                "जागा",
                "नोकरी"
            ],
            "theme": "ही “मिळते” :-)",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "गुंड्या",
                "मोरू",
                "मैना",
                "काऊ"
            ],
            "theme": "चिमणरावांचे चऱ्हाट (पुस्तक व मालिका) मधील पात्रे ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 1
        },
        {
            "words": [
                "नदी",
                "वीज",
                "वारा",
                "रस्ता"
            ],
            "theme": "वाहणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पाणी",
                "काच",
                "हवा",
                "पोकळी"
            ],
            "theme": "पारदर्शक गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "विविध",
                "नाना",
                "अनेक",
                "निरनिराळे"
            ],
            "theme": "बरेच, खूप या अर्थी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "आवाज",
                " जत्रा",
                " अक्षर",
                "किस्त्रीम"
            ],
            "theme": "काही प्रसिद्ध दिवाळी अंक ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "भर",
                "ओढ",
                "ठेव",
                "बस"
            ],
            "theme": "नामरुपे आणि क्रियेची आज्ञार्थ रुपे",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                42.195,
                " अथेन्स",
                "केनिया",
                "कीपटूम"
            ],
            "theme": " मॅरेथॉनशी संबंधित ",
            "sharedBy": "HVN Unplugged",
            "difficulty": 1
        },
        {
            "words": [
                "दोर",
                " सुळका",
                "चढण",
                "खिळे"
            ],
            "theme": "गिर्यारोहणाशी संबंधित ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "तिकीट",
                "आरक्षण",
                "स्थानक",
                "गंतव्य"
            ],
            "theme": "रेल्वे प्रवास ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 0
        },
        {
            "words": [
                "अमूल",
                "गवळी",
                "म्हैस",
                "गाय"
            ],
            "theme": "दूध देणारे :)",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 1
        },
        {
            "words": [
                "तलफ",
                "मंदी",
                "जाग",
                "शिंक"
            ],
            "theme": "ही “येते” :-)",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कात",
                "शिडी",
                "खवले",
                "काटेरी जीभ"
            ],
            "theme": "'साप' या विषयाचा शब्दबंध",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "चुना",
                "सुपारी",
                "बडीशेप",
                "गुलकंद"
            ],
            "theme": "विडा/पान बनवण्यासाठी पदार्थ ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 1
        },
        {
            "words": [
                "वासुकी",
                "तक्षक",
                "कालिया",
                "कर्कोटक"
            ],
            "theme": "पुराणातील काही नागांची नावे ",
            "sharedBy": "HVN Unplugged",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "बहर",
                "पालवी",
                "मोड",
                "कळी"
            ],
            "theme": "वनस्पतीं वर येणार्‍या नव्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "छापा",
                "टाकसाळ",
                "चिल्लर",
                "पावली"
            ],
            "theme": "चलनी नाणी या विषयावरील शब्दबंध ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "बाई",
                "श्याम",
                "कवडी",
                "दाम"
            ],
            "theme": "\"विकत घेतला श्याम\" गाण्यातले शब्द",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सुदर्शन",
                "परमवीर",
                "अशोक",
                "मूलाधार"
            ],
            "theme": "चक्र",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "गारुडी",
                "फणा",
                "पंचमी",
                "पुंगी"
            ],
            "theme": "'नाग' या विषयाचा शब्दबंध ",
            "sharedBy": "HVN Unplugged",
            "difficulty": 1
        },
        {
            "words": [
                "झोप",
                "ठेच",
                "बस",
                "वाट"
            ],
            "theme": "ही “लागते” :-)",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "आग",
                "माल",
                "बैल",
                "घोडा"
            ],
            "theme": "गाडी प्रत्यय लावून अर्थपूर्ण ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 0
        },
        {
            "words": [
                "उचलणे",
                "जीभ",
                "लावणे",
                "टाळ्याला"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "काटा",
                " टिकटिक",
                "वेळ",
                "ठोके"
            ],
            "theme": " घड्याळ ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "बहार",
                "नंद",
                "शिव",
                "चार"
            ],
            "theme": "'सदा' हा उपसर्ग लावून अर्थपूर्ण शब्द",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "मूळ",
                "स्वाती",
                "हस्त",
                "चित्रा"
            ],
            "theme": "नक्षत्रं",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "शह",
                "राणी",
                "घोडा",
                "मात"
            ],
            "theme": "बुद्धिबळाच्या पटावर",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "ग्रह",
                "तारे",
                "कृष्णविवर",
                "धूमकेतू"
            ],
            "theme": "खगोलशास्त्रीय",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 0
        },
        {
            "words": [
                "म्हैस",
                "गटणे",
                "पौष्टिक",
                "वरात"
            ],
            "theme": "पु लं च्या लिखाणातून ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "चक्राकार",
                "खमंग",
                "भाजणी",
                "कुरकुरीत"
            ],
            "theme": "चकली",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मानस",
                "दाल",
                "पिचोला",
                "वेण्णा"
            ],
            "theme": "तलाव",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "उत्प्रेक्षा",
                "यमक",
                "रूपक",
                "उपमा"
            ],
            "theme": "मराठी भाषालंकार",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "दैव",
                "देते",
                "कर्म",
                "नेते"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "विद्या",
                "भूमी",
                "कृती",
                "श्रद्धा"
            ],
            "theme": "हिंदी चित्रपटांमधील मधील काही अभिनेत्री ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "आलीया",
                "भोगासी",
                "असावे",
                "सादर "
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "विद्युत",
                "संथ",
                "वर्तुळाकार",
                "ब्राऊनिअन"
            ],
            "theme": "गती/हालचाली चे प्रकार",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "फेस",
                "तुषार",
                "खळखळाट",
                "तरंग"
            ],
            "theme": "समुद्राच्या लाटां बाबत",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "कल्पवृक्ष",
                "पांचजन्य",
                "कामधेनू",
                "हलाहल"
            ],
            "theme": "समुद्र मंथनातून मिळालेल्या काही गोष्टी ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "आग",
                "रामेश्वर",
                "बंब ",
                "सोमेश्वर"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "बोट",
                "माड",
                "लाटा",
                "शिंपला"
            ],
            "theme": "समुद्र किनाऱ्यावर वर दिसणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "अडीच",
                "चौसष्ट",
                "कॅसलिंग",
                "एन पासंट"
            ],
            "theme": "बुद्धिबळाच्या पटावर",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "दोन",
                "तीन",
                "पाच",
                "सात"
            ],
            "theme": "पहिल्या ४ मूळ संख्या",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कागद ",
                " छपाई",
                "संपादकीय",
                "पत्रकार "
            ],
            "theme": " वर्तमान पत्र ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 0
        },
        {
            "words": [
                "देव",
                "सराफ",
                "फुले",
                "लागू"
            ],
            "theme": "जुन्या मराठी चित्रपटातल्या अभिनेत्यांची आडनावे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "पेला",
                "खिडकी",
                "भिंग",
                "दौत"
            ],
            "theme": "काचेच्या वस्तु",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "अंथरुण",
                "पाहणे",
                "पाय",
                "पसरणे"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "झरा",
                "झाडे",
                "पक्षी",
                "पाऊस"
            ],
            "theme": "निसर्ग ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 1
        },
        {
            "words": [
                "तिरंगा",
                "फडकवणे",
                "स्तंभ",
                "मानवंदना"
            ],
            "theme": "झेंड्या बाबत",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "भोवरा",
                "आकाशगंगा",
                "पंखा",
                "वावटळ"
            ],
            "theme": "फिरणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "भूलभुलैया",
                "माया",
                "चकवा",
                "शोभादर्शक"
            ],
            "theme": "ज्यानी फसवणूक होते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "भक्ती",
                "प्रवचन",
                "नामजप",
                "पारायण"
            ],
            "theme": " अध्यात्म ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मूर्ती",
                "कळस",
                "गाभारा",
                "प्रसाद"
            ],
            "theme": "देऊळ ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 0
        },
        {
            "words": [
                "उथळ",
                "पाणी",
                "खळखळाट",
                "फार "
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "वरूण",
                "धो धो",
                "मेघ",
                "वीज"
            ],
            "theme": "पाऊस",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पाऊस",
                "वीज",
                "उल्का",
                "गारा"
            ],
            "theme": "आकाशातून पडणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "क्वार्टझ",
                "टायटन",
                "पट्टा",
                "वेळ"
            ],
            "theme": "वॉच",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "किरण",
                "सुहास",
                "चारु",
                "शीतल"
            ],
            "theme": "मराठी “यूनिसेक्स्” नावं",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "नास्तिक",
                "सरफरोशी",
                "बॉम्ब",
                "फाशी"
            ],
            "theme": "भगतसिंग यांच्या जीवनाशी संबंधित ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "गांधी",
                " राजवाडा",
                "संग्रहालय",
                "स्थानबद्ध"
            ],
            "theme": "आगा खान पॅलेस",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "उंदीर",
                "वाघ",
                "रेडा",
                "गरुड"
            ],
            "theme": "देवतांची वाहने",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "तांबड",
                "झुंजुमुंजु",
                "किलबिल",
                "भूपाळी"
            ],
            "theme": "पहाट",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "देव",
                "यम",
                "मेघ",
                "राम"
            ],
            "theme": "ज्यांना \"दूत\" जोडलेले शब्द आहेत (मेघदूत, ...)",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "अशोक स्तंभ",
                "तवा",
                "ऐरण",
                "घण"
            ],
            "theme": "लोह",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "वीट",
                "मडकं",
                "देशी दारू",
                "कोळसा"
            ],
            "theme": "भट्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "पद",
                "गट",
                "तट",
                "फूट"
            ],
            "theme": "राजकारणातल्या उचित अनुचित गोष्टी ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 1
        },
        {
            "words": [
                "आग",
                "पुणे",
                " काका",
                "बाजीराव"
            ],
            "theme": "पुणे स्थित शनिवार वाडा वास्तू संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कागद",
                "रंग",
                "पाणी",
                "ब्रश"
            ],
            "theme": "चित्रकला (पेंटिंग)",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 0
        },
        {
            "words": [
                "दिल्ली",
                "गोंधळ",
                "गल्ली",
                "मुजरा"
            ],
            "theme": "राजकारण आणि त्या आधारित चित्रपटाचे नाव ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 1
        },
        {
            "words": [
                "सकाळ",
                "हिंदू",
                "एक्सप्रेस",
                "सामना"
            ],
            "theme": "वृत्तपत्रे ज्यांच्या नावाचा दुसरा अर्थही होतो",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "परदेश",
                "घर",
                "आनंद",
                "माहेर"
            ],
            "theme": "ज्याच्यात राहता येतं अश्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "दही",
                "ताडी",
                "पाव",
                "इडली"
            ],
            "theme": "आंबवलेले पदार्थ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "नंदा",
                "नाथा",
                "लखू",
                "गजा"
            ],
            "theme": "व्यक्ती आणि वल्ली' मधील काही व्यक्तिरेखा ",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "वानर",
                "अस्वल",
                "गिधाड",
                "मृग"
            ],
            "theme": "रामायणात उल्लेख असलेले  प्राणी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "झेब्रा",
                "जिराफ",
                "चित्ता",
                "तरस"
            ],
            "theme": "आफ्रिकेत आढळणारे प्राणी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "मगर",
                "कासव",
                "पाणघोडा",
                "बेडूक"
            ],
            "theme": "उभयचर प्राणी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "लिफाफा",
                " आंतरदेशीय",
                " खुशाली ",
                "मुद्रांक"
            ],
            "theme": "पत्रव्यवहार संबंधी ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "पाट",
                "सतरंजी",
                "खुर्ची",
                "मोडा"
            ],
            "theme": " यावर बसतात  ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 1
        },
        {
            "words": [
                "काख",
                "कळसा",
                "गाव",
                "वळसा "
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "अंक",
                "फराळ",
                "उटणं",
                "लक्ष्मी"
            ],
            "theme": "दिवाळी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "नाच",
                "येणे",
                "अंगण",
                "वाकडे"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "केदार",
                "आसावरी",
                "सारंग",
                "कल्याण"
            ],
            "theme": "काही राग",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "वारा",
                "तापमान",
                " अतिवृष्टी",
                "चक्रीवादळ"
            ],
            "theme": "हवामानशास्त्र वेधशाळा आदींशी  संबंधित ",
            "sharedBy": "ऋतुजा ",
            "difficulty": 0
        },
        {
            "words": [
                "समुद्र",
                "जाळे",
                "होडी",
                "मासळी"
            ],
            "theme": "कोळ्याच्या जीवनाशी संबंधित ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 1
        },
        {
            "words": [
                "चतुर्दशी",
                "बिज",
                "बारस",
                "पूजन"
            ],
            "theme": "दिवाळीच्या दिवसांचे दुसरे शब्द प्रत्यय",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "आधी",
                "पोट",
                "मग",
                "विठ्ठल"
            ],
            "theme": "एका मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "इकडे",
                "आड",
                "तिकडे",
                "विहीर"
            ],
            "theme": "मराठी म्हणीतील शब्द ",
            "sharedBy": "एक शुभचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "सूर्यफूल",
                "जवस",
                "ऑलिव्ह",
                "शेंगदाणा"
            ],
            "theme": "तेल बिया ",
            "sharedBy": "मीनल अभ्यंकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पक्ष",
                " मत",
                " चिन्ह",
                "सभा"
            ],
            "theme": "निवडणूक ",
            "sharedBy": "सुभाष भावे",
            "difficulty": 0
        },
        {
            "words": [
                "⁠व्यक्ती",
                " बटाटा",
                " तुजपाशी",
                "सुंदर"
            ],
            "theme": " पु. ल. देशपांडे यांच्या पुस्तकांच्या नावातील शब्द",
            "sharedBy": "ऋतुजा ",
            "difficulty": 1
        },
        {
            "words": [
                "चाखणे",
                "चावणे",
                "गिळणे",
                "खाणे"
            ],
            "theme": "खाण्याशी संबंधित क्रिया",
            "sharedBy": "AI प्रणाली",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मूर्ती",
                "मिरवणूक",
                "भाद्रपद",
                "आरती"
            ],
            "theme": "गणेशोत्सव",
            "sharedBy": "HVN Unplugged",
            "difficulty": 0
        },
        {
            "words": [
                "ग्वाल्हेर",
                "जयपूर",
                "मेवाती",
                "भेंडी बाजार"
            ],
            "theme": "हिंदुस्थानी शास्त्रीय संगीतातली घराणी",
            "sharedBy": "HVN Unplugged",
            "difficulty": 1
        },
        {
            "words": [
                "नांगरणी",
                "रब्बी",
                "खरीप",
                "पेरणी"
            ],
            "theme": "कृषीशी निगडित संज्ञा",
            "sharedBy": "AI प्रणाली",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "दव",
                "सुपारी",
                "भोजन",
                "चुना"
            ],
            "theme": "पानावर सापडणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "लता",
                "ममता",
                "गांधी",
                "गुलजार"
            ],
            "theme": "नेहेमी पांढरी वस्त्रे परिधान करणारे लोक ",
            "sharedBy": "एक हितचिंतक",
            "difficulty": 1
        },
        {
            "words": [
                "वामकुक्षी",
                "पेन्शन",
                "नातवंडं",
                "निवृत्ती"
            ],
            "theme": "ज्येष्ठ नागरिक ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "रियो",
                "ॲमेझॉन",
                "पोर्तुगिज",
                "फुटबॉल"
            ],
            "theme": "ब्राझील",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "पिझ्झा",
                "गॅलेलियो",
                "फेरारी",
                "व्हेनीस"
            ],
            "theme": "ईटली",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "सुशी",
                "फ्युजी",
                "टोयोटा",
                "बुलेट ट्रेन"
            ],
            "theme": "जपान",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "काडेपेटी",
                "भिंग",
                "गारगोट्या",
                "लायटर"
            ],
            "theme": "ज्या गोष्टींनी आग पेटवता येते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "घोंगडं",
                "पाणी",
                "माती",
                "अग्नीरोधक"
            ],
            "theme": "ज्या गोष्टींनी आग विजवता येते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "स्वयंपाक",
                "समई",
                "भट्टी",
                "शेकोटी"
            ],
            "theme": "अग्नीचे ऊपयोग",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "उद्घाटनाची रिबिन",
                "पगार",
                "भाताचं पिक",
                "केस"
            ],
            "theme": "ज्या गोष्टी कापल्या जातात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "चाकू",
                "विळी",
                "करवत",
                "खुर्पं"
            ],
            "theme": "ज्या गोष्टी कापतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "शल्यविशारद",
                "शिंपी",
                "पाकीटमार",
                "स्वयंपाकी"
            ],
            "theme": "ज्यांच्या व्यवसायांत कापावे लागते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "तोरण",
                "राखी",
                "मुंडावळ्या",
                "नाडी"
            ],
            "theme": "गाठ घालून बांधण्याच्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "हार",
                "टायर",
                "मेदूवडा",
                "बांगडी"
            ],
            "theme": "लूप / नेढे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "चष्मा",
                "टक्कल",
                "हातमाग",
                "पांढरे धोतर"
            ],
            "theme": "महात्मा गांधी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "ध्रुव",
                "गंगोत्री",
                "दक्षिण",
                "पेंगविन"
            ],
            "theme": "अंटार्क्टिका",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "व्याध",
                "अभिजित",
                "सूर्य",
                "अरुंधती"
            ],
            "theme": "तारे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "सिंह",
                "मासे",
                "तराजू",
                "जोडपे"
            ],
            "theme": "राशी चिन्हे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पार्टी",
                "आतिषबाजी",
                "लाईटींग",
                "सोहळा"
            ],
            "theme": "सेलिब्रेशन, समारंभ, साजरा करणे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "स्पेशल",
                "प्रजासत्ताक दिन",
                "पुढले वर्ष",
                "मॅराथॉन"
            ],
            "theme": "२६ (सव्वीस)  - चित्रपट, वर्ष, मैल वगैरे ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "ॲटलास",
                "क्रेन",
                "मल्लेश्वरी",
                "जॅक"
            ],
            "theme": "जड गोष्टी उचलणारे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "नवीन",
                "मलम",
                "सरस",
                "सामोसा"
            ],
            "theme": "Palindromes (विलोमपदॆ)",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "बारामती",
                "सुलक्षणी",
                "पराकोटी",
                "कासावीस"
            ],
            "theme": "\"संख्याशाली\" शब्द (शब्दांत एक संख्या लपलेली )",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "टाचणी",
                "नांगर",
                "सरळ नाक",
                "खिळा"
            ],
            "theme": "टोकदार गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "तिमिर",
                "मॆत्र",
                "सोयरे",
                "चेतना"
            ],
            "theme": "अजरामर \"पसायदाना\"तले शब्द",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "हिम",
                "कार्य",
                "विद्या",
                "रुग्ण"
            ],
            "theme": "यांची घरे (आलये) आहेत",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "रात्र",
                "राजा",
                "राग",
                "राहू"
            ],
            "theme": "रा' ने सुरू होणारे शब्द",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "नवजीवन",
                "सकस",
                "कडक",
                "समास"
            ],
            "theme": "Palindromes, of course! (विलोमपदॆ)",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "राम",
                "पॆसा",
                "तथ्य",
                "हेतू"
            ],
            "theme": "\"अर्था\"चे अनेक पर्यायी शब्द",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "बांधवगड",
                "कॉर्बेट",
                "पेरियार",
                "काझिरंगा"
            ],
            "theme": "नॅशनल पार्क",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कांचीपुरम",
                "रामेश्वर",
                "मीनाक्षी",
                "कावेरी"
            ],
            "theme": "तामिळनाडू",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "सुरत",
                "आनंद",
                "दांडी",
                "मोरबी"
            ],
            "theme": "गुजरात मधली गावे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "संक्षिप्त",
                "आकुंचन",
                "शुष्क",
                "केंद्रित"
            ],
            "theme": "कमी होणे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "शांती श्लोक",
                "डेझर्ट खाणे",
                "रात्रीची झोप",
                "विसर्जन"
            ],
            "theme": "एखाद्या गोष्टीच्या शेवटी करायच्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "मूळ",
                "हलवा",
                "ससा",
                "लाल"
            ],
            "theme": "गाजर",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "ईश्वर",
                "आत्मा",
                "वीर",
                "अणू"
            ],
            "theme": "ज्यांच्या आधी \"परम\" जोडलेले शब्द आहेत",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "अंडं",
                "नारळ",
                "फुगा",
                "अमेरिकन फुटबॉल"
            ],
            "theme": "अंडाकृती गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "दोरखंड",
                "जॅकेट",
                "काटेरी बूट",
                "धाडसीपणा"
            ],
            "theme": "ऐस माऊंटेनीयरींग ला लागणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "भुयार",
                "विहीर",
                "तळघर",
                "खाण"
            ],
            "theme": "जमिनीत खोदून केलेल्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "वाईन",
                "जंतुनाशक",
                "व्यसन",
                "ज्वलनशील "
            ],
            "theme": "अल्कोहोल",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "ढोल",
                "वीज",
                "सायरन",
                "फटाका"
            ],
            "theme": "मोठा आवाज करणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "काथ्या",
                "खराटा",
                "करवंटी",
                "कॉयर"
            ],
            "theme": "नारळाच्या झाडापासून बनवलेल्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "लखोबा",
                "राधेश्शाम",
                "दिवाकर",
                "अशोक"
            ],
            "theme": "लोखंडे, महाराज, दातार, परांजपे -- छे, छे, तो मी नव्हेच!",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "प्रस्थ",
                "स्वप्न",
                "क्रुद्ध",
                "व्रात्य"
            ],
            "theme": "दोन जोडाक्षरांचे शब्द",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "ओतप्रोत",
                "उदंड",
                "ओसंडून",
                "तुडुंब"
            ],
            "theme": "अती, खूप जास्त   ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "डॉन",
                "राहीन",
                "सीन",
                "थेम्स"
            ],
            "theme": "युरोपियन नद्या",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "मैफल",
                "मेंदी",
                "स्वप्नं",
                "संध्याकाळचं आकाश"
            ],
            "theme": "ज्या गोष्टी रंगतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "ततकार",
                "तोडा",
                "मुद्रा",
                "चक्कर"
            ],
            "theme": "कथ्थक",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "धर्म",
                "ग्लानी",
                "भारत",
                "यदा"
            ],
            "theme": "यदा यदा ही धर्मस्य...",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "साधु",
                "विनाश",
                "संभव",
                "युग"
            ],
            "theme": "परितत्राणाय साधूनां .... ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "देवदत्त",
                "अनंतविजय",
                "सुघोष",
                "मणिपुष्पक"
            ],
            "theme": "पांडवांच्या शंखांची नावे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "घोडा",
                "हॅट",
                "पिस्तूल ",
                "बूट"
            ],
            "theme": "काऊबॉय संबंधी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "ठाकुर",
                "बसंती",
                "विरु",
                "जय"
            ],
            "theme": "शोले सिनेमातील पात्रे ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "दोस्ती",
                "मेहबुबा",
                "होली",
                "हसिना"
            ],
            "theme": "शोले सिनेमातल्या गाण्यांमधले शब्द ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "करोड ",
                "राष्ट्र ",
                "गण",
                "सेना "
            ],
            "theme": "ज्यांना \"पती\" जोडता येतं ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "शांती ",
                "शिव ",
                "वानर",
                "वायू "
            ],
            "theme": "ज्यांना \"सेना\" जोडता येतं ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "हिरा ",
                "धूमकेतू ",
                "हॅट ट्रीक",
                "प्लॅटिनम "
            ],
            "theme": "दुर्मिळ गोष्टी ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "दही ",
                "पाणी ",
                "ओम ",
                "कोल्हा "
            ],
            "theme": "ज्यांना \"पुरी\" जोडून शब्द आहेत ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "चहा ",
                "सिल्क ",
                "कुंगफू",
                "कागद "
            ],
            "theme": "चीन मध्ये उत्पत्ती झालेल्या गोष्टी ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "किमची",
                "गंगनम",
                "तायक्वांदो",
                "ह्युनडाई"
            ],
            "theme": "साऊथ कोरिया ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "छाती ",
                "खिळा ",
                "तंबू",
                "थाप"
            ],
            "theme": "ज्या गोष्टी ठोकता येतात ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "कठडा ",
                "जर ",
                "कुंपण ",
                "समास/मार्जिन"
            ],
            "theme": "एखाद्या गोष्टीच्या बाजूने असलेल्या गोष्टी ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "वय",
                "नखं",
                "लोकसंख्या",
                "केस "
            ],
            "theme": "कायम वाढणाऱ्या गोष्टी ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "बटाटा ",
                "टोमॅटो ",
                "पेरू ",
                "काजू "
            ],
            "theme": "दक्षिण अमेरिकेत उत्पत्ती झालेल्या गोष्टी ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "गोपूर",
                "मंडप",
                "गाभारा",
                "मूर्ती"
            ],
            "theme": "मंदिराचे भाग",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "मुकुट",
                "टोपली",
                "ओझं",
                "टोपी"
            ],
            "theme": "डोक्यावर ठेवता येणाऱ्या गोष्टी  ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "चिंब",
                "श्रावण ",
                "धारा",
                "छत्री"
            ],
            "theme": "पाऊस",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "बिंब",
                "होळी",
                "ससा",
                "रोमँटिक"
            ],
            "theme": "पोर्णिमेचा चंद्र",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "टिंब",
                "पूर्णविराम",
                "कुंकू ",
                "तीळ"
            ],
            "theme": "ठिपका",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "जुगार",
                "तंबाखू",
                "रील",
                "खरेदी"
            ],
            "theme": "व्यसने",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "शेयर्स",
                "कर्ज",
                "गहाण ठेव",
                "वेठबिगारी"
            ],
            "theme": "भांडवल उभं करण्याची साधने",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "विपणन",
                "विक्री",
                "आयटी",
                "वित्त"
            ],
            "theme": "कंपनीतील विभाग (डिपार्टमेंटस्)",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "काटकोन",
                "वर्ग",
                "ग्रीस",
                "सिद्धांत "
            ],
            "theme": "पायथॅगोरस",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "क्वांटम",
                "मांजर",
                "वेव्ह",
                "संभाव्यता"
            ],
            "theme": "श्रोडिंगर",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "ऑडिओ उपकरणे",
                "आईन्स्टाईन कंडेंन्सेट ",
                "पद्म विभूषण",
                "बोझॉन"
            ],
            "theme": "बोस",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "राहुल",
                "राजीव",
                "फिरोझ",
                "संजय"
            ],
            "theme": "गांधी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "शक्ती",
                "महेंद्र ",
                "अनिल",
                "संजीव "
            ],
            "theme": "कपूर",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "जहांगीर",
                "रतन",
                "नवल",
                "दोराब"
            ],
            "theme": "टाटा",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "करनम",
                "लोवलीना",
                "साक्षी",
                "मीराबाई"
            ],
            "theme": "भारतीय ऑलिंपिक पदक विजेत्या स्त्रिया ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "अल्का",
                "उषा",
                "श्रेया",
                "अनुराधा"
            ],
            "theme": "हिंदी गायिका ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "लक्ष्मी",
                "अहिल्या",
                "तारा",
                "दुर्गावती"
            ],
            "theme": "शूर ऐतिहासिक राण्या",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सत्यभामा",
                "भद्रा",
                "रुक्मिणी ",
                "कालिंदी"
            ],
            "theme": "कृष्णाच्या पत्नी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "सुप्रिया",
                "ललिता",
                "ममता",
                "सुषमा"
            ],
            "theme": "स्त्री राजकारणी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "आशा",
                "शर्मिला",
                "माला",
                "साधना"
            ],
            "theme": "1960s मधल्या हिन्दी अभिनेत्री ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "जना",
                "सखु",
                "मुक्ता",
                "मीरा"
            ],
            "theme": "स्त्री संत",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "रेखा",
                "सुष्मिता",
                "अमिशा",
                "कंगना"
            ],
            "theme": "अविवाहीत हिंदी अभिनेत्री ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "रंजना",
                "सीमा ",
                "चित्रा",
                "आशा "
            ],
            "theme": "जुन्या काळातल्या मराठी अभिनेत्री",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सरस",
                "कारीका",
                "मलम",
                "नयन"
            ],
            "theme": "Palindromes (विलोमपदॆ)",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "टॉनिक ",
                "अंडं",
                "दुध ",
                "डिंकाचे लाडू"
            ],
            "theme": "पौष्टिक पदार्थ ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "साबण ",
                "बियर ",
                "समुद्राची लाट ",
                "सोडा "
            ],
            "theme": "फेस येणार्‍या गोष्टी ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "दुर्बीण ",
                "बासरी ",
                "पुंगळी ",
                "पेने पास्ता"
            ],
            "theme": "पोकळ दंडगोलाकार वस्तु ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "दृष्टी",
                "श्रवण",
                "स्पर्श",
                "चव"
            ],
            "theme": "ज्ञानेंद्रिये",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "देखावा",
                "संगीत ",
                "रेशीम ",
                "मसाला"
            ],
            "theme": "पंचेंद्रियांना सुखकारक गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "दहा",
                "क्ष किरणे",
                "चूक",
                "गुणाकार"
            ],
            "theme": "X",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "पतंग ",
                "विमान ",
                "डॉल्फिन ",
                "हनुमान"
            ],
            "theme": "शेपटी असलेल्या गोष्टी ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "फुलबाजी",
                "जातं",
                "सुकाणू ",
                "आरती"
            ],
            "theme": "हाताने गोल फिरवलेल्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "तोठरा",
                "गायन",
                "थायरॉईड",
                "मंगळसूत्र "
            ],
            "theme": "गळ्या संबंधित ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "टिचकी",
                "अंगठी",
                "नखं",
                "सतार वादन"
            ],
            "theme": "बोटां संबंधित ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "मधुचंद्र ",
                "निरोप",
                "स्वागत समारंभ",
                "हुश्श्य"
            ],
            "theme": "लग्न कार्या नंतर करायच्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "गोकर्ण",
                "उडुपी",
                "जोग",
                "मंगलोर "
            ],
            "theme": "कोस्टल कर्नाटक",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "चामुंडी",
                "पाक",
                "पॅलेस",
                "चंदन"
            ],
            "theme": "मैसूर संबंधित ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "दूध",
                "कामधेनू",
                "गवत",
                "रवंथ"
            ],
            "theme": "गाई संबंधित ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "हवेली",
                "भोर",
                "मावळ",
                "खेड"
            ],
            "theme": "पणे जिल्ह्यातील तालुके",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "वाडा",
                "अर्धा दिवस",
                "सॅटर्न",
                "ओंकारेश्वर मंदिर "
            ],
            "theme": "शनिवार संबंधित ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "लाल",
                "ताज",
                "हवा",
                "मोती"
            ],
            "theme": "‐ महाल",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "स्वार",
                "पेरू",
                "इंडिया",
                "चर्च"
            ],
            "theme": "- गेट",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "पंचवटी",
                "वेताळ",
                "तळजाई",
                "हनुमान"
            ],
            "theme": "पुण्यातल्या टेकड्या",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "भस्म",
                "भोळा",
                "तप",
                "गंगा"
            ],
            "theme": "शंकरा संबंधित ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सतगुरु",
                "जमशेटजी",
                "सांता क्लाॅज",
                "मोदी"
            ],
            "theme": "पांढरी दाढी वाले",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "रामदेव ",
                "साई",
                "अली",
                "आई"
            ],
            "theme": "- बाबा",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "पेस्तन काका",
                "भाभा",
                "वाडिया",
                "सॅम बहाद्दुर"
            ],
            "theme": "पारसी लोकं",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "येसूबाई",
                "छावा",
                "तुळापूर",
                "शिवाजी"
            ],
            "theme": "संभाजी संबंधित ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "हुबळीकर",
                "शेळके",
                "कार्टं",
                "बाई"
            ],
            "theme": "शांता संबंधित ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "महेश",
                "काजळ",
                "पैसे",
                "कारनामे"
            ],
            "theme": "काळे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "चंचल",
                "शीतल ",
                "निर्मल",
                "कोमल"
            ],
            "theme": "\"सत्यम शिवम् सुंदरम\" मधिल गाण्याचे बोल",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "खैबर",
                "पावन",
                "सिंबायोसिस ",
                "शेलार"
            ],
            "theme": "खिंड",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "योग",
                "चित्त",
                "वृत्ती",
                "निरोध"
            ],
            "theme": "पतंजली योगसूत्रातील दुसर्‍या वाक्यातील शब्द ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "विधी",
                "रुद्र",
                "बच्चन",
                "पंचामृत"
            ],
            "theme": "अभिषेक ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "अक्रोड",
                "रुद्राक्षं",
                "सफरचंद",
                "जरदाळू "
            ],
            "theme": "हिमालयात उगवणारी फळे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "पोहे",
                "कढिपत्ता",
                "फोडणी",
                "दाणे"
            ],
            "theme": "चिवड्याचे घटक",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कालि",
                "राम",
                "यशु",
                "तुलसी"
            ],
            "theme": " - दास नावाच्या प्रसिद्ध व्यक्ती",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "गरम तेल",
                "कृष्ण ",
                "आगाशे",
                "गांधी"
            ],
            "theme": "मोहन",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "ताडदेव",
                "शीव",
                "फोर्ट",
                "प्रभादेवी"
            ],
            "theme": "मुंबईचे भाग",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मत",
                "सत्ता",
                "शाही",
                "मान्य"
            ],
            "theme": "लोक - ...",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "पदर",
                "शबनम",
                "डोली",
                "झोळी"
            ],
            "theme": "खांद्यावर घ्ययच्या / टांगायच्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "फटका",
                "बांबू",
                "पाण्यात",
                "वाया"
            ],
            "theme": "पैसे गमावणे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "चिवडा",
                "लाडू",
                "चकली",
                "करंजी"
            ],
            "theme": "दिवाळीचे पदार्थ ",
            "sharedBy": "शुभचिंतक",
            "difficulty": 0
        },
        {
            "words": [
                "तिळगुळ",
                "पतंग",
                "काळे कपडे",
                "मकर"
            ],
            "theme": "संक्रांत ",
            "sharedBy": "स्नेहा",
            "difficulty": 1
        },
        {
            "words": [
                "पानं",
                " छपाई ",
                " मूल्य",
                " मुखपृष्ठ"
            ],
            "theme": "पुस्तक",
            "sharedBy": "शिरीष केळकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पुलकेशी",
                " हर्षवर्धन",
                " समुद्रगुप्त",
                " ललितादित्य"
            ],
            "theme": "पहिले सहस्रक",
            "sharedBy": "विकास पाटणकर",
            "difficulty": 0
        },
        {
            "words": [
                "समीकरण",
                " कर्ण",
                " कंस",
                " सूत्र"
            ],
            "theme": "गणित!",
            "sharedBy": "नुपुरा डोरले",
            "difficulty": 1
        },
        {
            "words": [
                "जम",
                "घसा",
                "तबलजी",
                "धक्का"
            ],
            "theme": "बसणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "लोह",
                "सिंह ",
                "राज",
                "बांधव"
            ],
            "theme": "- गड",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "यष्टी",
                "झेल",
                "कप्तान",
                "पंच"
            ],
            "theme": "क्रिकेट मधील संज्ञा",
            "sharedBy": "रविकिरण देशपांडे ",
            "difficulty": 1
        },
        {
            "words": [
                "शमी",
                "सुंदर",
                "विराट",
                "यशस्वी"
            ],
            "theme": "क्रिकेटपटूंची नावे ",
            "sharedBy": "रविकिरण देशपांडे ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "चिंचोका",
                "चॉकलेट ",
                "शिसवी लाकूड",
                "खजूर "
            ],
            "theme": "चॉकलेटी रंगाच्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "जहाज ",
                " लाकूड ",
                " तेल ",
                " कापूस"
            ],
            "theme": "पाण्यावर तरंगणाऱ्या गोष्टी",
            "sharedBy": "कौशिक कुलकर्णी ",
            "difficulty": 1
        },
        {
            "words": [
                "बेल",
                "तुळस",
                "आघाडा",
                "दुर्वा"
            ],
            "theme": "देवाला वाहायची पाने",
            "sharedBy": "रविकिरण देशपांडे ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "नारळ",
                "विहीर ",
                "डोळे",
                "निवडुंग"
            ],
            "theme": "ज्यात पाणी असते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "धंदा",
                "हवा",
                "तेल",
                "औषध "
            ],
            "theme": "या शब्दांना \"पाणी\" जोडतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "जळगांवकर",
                "भाता",
                "सूर",
                "रीड"
            ],
            "theme": "हार्मोनियम",
            "sharedBy": "शिरीष केळकर ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "रेडा",
                "गुराखी",
                "पु. ल.",
                "दुध "
            ],
            "theme": "म्हैस संबंधित ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "ओंजळ ",
                "नव्याण्णव ",
                "ऑक्सिजन (O2) ",
                "टाळी"
            ],
            "theme": "दोन समान गोष्टीतून जोडून केलेल्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "उडी",
                "सागर",
                "कमला",
                " जन्मठेप"
            ],
            "theme": "सावरकर",
            "sharedBy": "नुपुरा डोरले",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "हळद",
                "टॅल्कम",
                "त्रिफळा",
                "गुलाल"
            ],
            "theme": "पूड, चूर्ण ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "नाका",
                "नस",
                "तट",
                "जमाव"
            ],
            "theme": "--- बंदी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "समुद्र ",
                " हवा ",
                " रस्ते ",
                " रूळ"
            ],
            "theme": "वाहतूकीचे मार्ग ",
            "sharedBy": "कौशिक कुलकर्णी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "हस्त",
                "स्वाती",
                "मृग",
                "रेवती"
            ],
            "theme": "नक्षत्र",
            "sharedBy": "रविकिरण देशपांडे ",
            "difficulty": 0
        },
        {
            "words": [
                "कंठ",
                "कटी",
                "नेत्र",
                "कर्ण"
            ],
            "theme": "अवयव",
            "sharedBy": "रविकिरण देशपांडे ",
            "difficulty": 1
        },
        {
            "words": [
                "द्रोण",
                "कृप",
                "धर्म",
                "गंगा"
            ],
            "theme": "महाभारत व्यक्तिरेखा",
            "sharedBy": "रविकिरण देशपांडे ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मेळा",
                "विवाह",
                "रास",
                "कर्ण"
            ],
            "theme": "कुंभ---",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "बाजू",
                "पंधरवडा",
                "संघटना",
                "पंख"
            ],
            "theme": "पक्ष चे विविध अर्थ",
            "sharedBy": "समीर जोशी ",
            "difficulty": 1
        },
        {
            "words": [
                "लस",
                "सुई",
                "कान",
                "काटा"
            ],
            "theme": "टोचायच्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "यष्टी",
                "झेल",
                "कप्तान",
                "पंच"
            ],
            "theme": "क्रिकेट मधील संज्ञा",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "आरती",
                " आश्रम",
                "परिक्रमा",
                " कन्यापूजन"
            ],
            "theme": "नर्मदा  परिक्रमा",
            "sharedBy": "अनघा अनिल जोशी",
            "difficulty": 1
        },
        {
            "words": [
                "पोलीस ",
                "जेम्स बॉण्ड",
                "मूळ बिंदू (origin)",
                "शतक"
            ],
            "theme": "शून्य शून्य (दोन शून्यांचा समावेश)",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "केदार",
                " मधुवंती",
                " मालकंस",
                "भूप"
            ],
            "theme": "हिंदुस्थानी संगीत राग",
            "sharedBy": "नुपुरा डोरले",
            "difficulty": 0
        },
        {
            "words": [
                "कट",
                "पॅक उप",
                "टेक",
                "रोल"
            ],
            "theme": "फिल्म शूटिंग करताना वापरात येणारे शब्द ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "वर",
                "पाक",
                "भू",
                "चलित"
            ],
            "theme": "स्वयं __",
            "sharedBy": "समीर जोशी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "तर्री",
                "पेज",
                "शोर्बा",
                "सॅम्पल "
            ],
            "theme": "पातळ खाद्य पदार्थ ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "कान",
                "नाग",
                "जय",
                "खड्ग"
            ],
            "theme": " -- पूर नावाची भारतातील प्रसिद्ध गावे ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "रवा ",
                " साखर ",
                " तूप ",
                " काजू"
            ],
            "theme": "शिऱ्याचे घटक",
            "sharedBy": "कौशिक कुलकर्णी",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "इन्दु",
                "मृगांक",
                " सुधांशु",
                " सोम"
            ],
            "theme": "चंद्राची काही नावे ",
            "sharedBy": "HVN Unplugged",
            "difficulty": 0
        },
        {
            "words": [
                "रस",
                "गोडी",
                "रुची",
                "कल"
            ],
            "theme": "स्वारस्य या अर्थी",
            "sharedBy": "समीर जोशी ",
            "difficulty": 1
        },
        {
            "words": [
                "भरती",
                "ओहोटी",
                "ग्रहण",
                "अपोलो"
            ],
            "theme": "चंद्र कारणीभूत असणाऱ्या काही गोष्टी  ",
            "sharedBy": "HVN Unplugged",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "प्राण",
                "जीवन ",
                "प्रेम ",
                "शक्ती"
            ],
            "theme": "जुन्या हिंदी चित्रपटांमधील खलनायक",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "जंगल",
                "लूट",
                "पंडित",
                "गुरु"
            ],
            "theme": "भारतीय उत्पत्तीचे इंग्रजीत वापरले जाणारे शब्द ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "हिरकणी",
                " टकमक",
                " छत्रपती",
                " राजधानी"
            ],
            "theme": "किल्ले रायगड",
            "sharedBy": "विवेक वासुदेव चितळे ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पाचू",
                "पुष्कराज",
                "माणिक",
                "नीलम"
            ],
            "theme": "मौल्यवान रत्ने",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "पाखरू",
                " बाजी",
                " झाडे",
                " वडी"
            ],
            "theme": "फूल उपसर्ग लागून अर्थपूर्ण शब्द",
            "sharedBy": "आहना आगाशे",
            "difficulty": 1
        },
        {
            "words": [
                "लता",
                "सुमन",
                "किशोरी",
                "आशा"
            ],
            "theme": "प्रसिद्ध गायिका",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "अंतुले",
                " पवार",
                " राणे",
                " नाईक"
            ],
            "theme": "महाराष्ट्राचे माजी मुख्यमंत्री",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "ठोसर",
                " रेळेकर",
                " आंबिले",
                " कुलकर्णी"
            ],
            "theme": "महाराष्ट्रातील प्रसिद्ध संतांची आडनावे",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "राम",
                "सिद्ध",
                "हाय",
                "मिस"
            ],
            "theme": "--- टेक",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पिंड",
                "भोज्जा",
                "आभ्रे",
                "कपडे"
            ],
            "theme": "शिवण्याच्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "माल",
                "भाऊ",
                "वीज ",
                "आश्चर्य "
            ],
            "theme": "‐‐- चा धक्का",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "घोडा",
                "लोट",
                "चक्की",
                "चट"
            ],
            "theme": "पाण ---",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "धोबी",
                "खंबाटकी ",
                "मणिकर्णिका ",
                "अंबा"
            ],
            "theme": "--- घाट",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "पाचगणी",
                " सप्तशृंगी",
                " हजारीबाग",
                " सोलापूर"
            ],
            "theme": "नावात अंक असलेली शहरे",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "गंज",
                "पूर",
                "बाद",
                "वाडी"
            ],
            "theme": "गावांच्या नावांचे शेवट",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "दमयंती ",
                "शकुंतला ",
                "मेनका,",
                "चित्रलेखा"
            ],
            "theme": "राजा रवी वर्मा यांच्या चित्रातील स्त्रिया ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "अंबिका",
                "गंगा",
                "सत्यवती",
                "माद्री"
            ],
            "theme": "महाभारत पूर्वार्धातील स्त्रिया ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "द्रौपदी",
                " उलुपी",
                " चित्रांगदा",
                " सुभद्रा"
            ],
            "theme": "महाभारतातील अर्जुन पत्नी ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मीठ",
                "सुरमई ",
                "मोती",
                "पोवळं"
            ],
            "theme": "समुद्रात अढळणार्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "कोशी",
                "अलकनंदा ",
                "पद्मा",
                "गोमाती"
            ],
            "theme": "गंगेला डाव्याबाजूने मिळणार्या नद्या",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "मयुराक्षी",
                "सोने",
                "काली ",
                "दामोदर"
            ],
            "theme": "गंगेला उजव्याबाजूने मिळणार्या नद्या",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सोनल",
                "रोहिणी",
                "मल्लिका",
                "शोभना"
            ],
            "theme": "प्रसिद्ध शास्त्रीय नृत्यांगना",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "दौंड",
                " पुरंदर",
                " हवेली",
                " इंदापूर"
            ],
            "theme": "पुणे जिल्ह्यातील तालुके",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "फडणवीस",
                " बावनकुळे",
                " सातपुते",
                " आठवले"
            ],
            "theme": "नावात अंक असलेली आडनावे",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "ढोबळी मिरची",
                "द्राक्षं",
                "सफरचंद ",
                "टोमॅटो "
            ],
            "theme": "दोन, तीन वा अधिक वेगवेगळ्या रंगात येणाऱ्या भाज्या/फळं",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "कांदा ",
                "बटाटा ",
                "पालक ",
                "मिरची "
            ],
            "theme": "ज्याची भजी केली जातात ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "आवकाडो",
                "ऑलिव्ह",
                "ब्रोकोली",
                "चेरी"
            ],
            "theme": "आजकाल मंडईत सर्रास मिळणारी परदेशी भाज्या/फळं",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कर्क",
                "ह्रदय ",
                "क्षय",
                "कृष्ठ"
            ],
            "theme": "--- रोग",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "गळ",
                "पतंग",
                "सुई",
                "भवरा"
            ],
            "theme": "ज्यानां लांब दोरा लावतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "व्यायाम",
                "ताप",
                "कॉफी ",
                "भीती"
            ],
            "theme": "हृदयची गती वाढवणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "भितीदायक स्वप्न",
                "कडाडणारी वीज",
                "सळसळणारा साप",
                "किंकाळी"
            ],
            "theme": "दचकवणार्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "नेत्याचं भाषण ",
                "सिनेमातील हीरोगीरी",
                "सैनिकांची वीरता",
                "दुसर्‍याचे यश"
            ],
            "theme": "प्रोत्साहित करणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "कार्टून",
                "निवडक पु. ल.",
                "गुदगुल्या",
                "नायट्रस ऑक्साईड (N2O)"
            ],
            "theme": "हसवणार्या गोष्टी (N2O - Laughing Gas)",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "गाऊन",
                "हस्तांदोलन ",
                "पदवी",
                "चौकोनी टोपी"
            ],
            "theme": "दीक्षांत समारंभ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "भव्य इमारती",
                "प्राध्यापक",
                "ग्रंथालय ",
                "उच्च शिक्षण "
            ],
            "theme": "विद्यापीठ ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "परीक्षा",
                "अर्ज",
                "शुल्क",
                "बारावी पास "
            ],
            "theme": "विद्यापीठात प्रवेश मिळवण्यासाठी लागणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "चेन्नई",
                " धाराशिव",
                "प्रयागराज",
                " अहिल्यानगर"
            ],
            "theme": "नामांतरीत शहरे",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "सहन",
                " स्मरण",
                " स्त्री",
                " महा"
            ],
            "theme": " ___ शक्ती",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "विंध्य",
                " गंगा",
                " मराठा",
                " गण"
            ],
            "theme": "राष्ट्रगीतातील शब्द",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सुट्टी",
                " ऊन",
                " हापूस",
                " पंखा"
            ],
            "theme": "उन्हाळा",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "मोहरी",
                " जवस",
                " सोयाबीन",
                " एरंड"
            ],
            "theme": "तेलबिया",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "चित्र",
                " हाहा",
                " कला",
                " चर्म"
            ],
            "theme": "____ कार",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "ताक",
                "बेसन",
                "कढीपत्ता",
                "आलं"
            ],
            "theme": "कढीतले पदार्थ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "फ्लावर",
                "बटाटा",
                "ढोबळी मिरची ",
                "मटार"
            ],
            "theme": "पावभाजीतील पदार्थ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "मेथी",
                "पालक",
                "आळू",
                "चाकवत"
            ],
            "theme": "ज्यांची पातळ भाजी बनवतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "रंग",
                " सूर",
                " ऋषी",
                " सिंधू"
            ],
            "theme": "सप्त ____",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "वड",
                " माले",
                " जळ",
                " माझ"
            ],
            "theme": "___ गाव",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "पाल",
                " देव",
                " घर",
                " लाड"
            ],
            "theme": "_____घर",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "खास",
                "जरूर ",
                "तुफान ",
                "कैद"
            ],
            "theme": "अरबी भाषेतून मराठीत आलेले शब्द ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "ऑफिस ",
                "फोन",
                "बिस्किट ",
                "फोटो"
            ],
            "theme": "इंग्रजी भाषेतून मराठीत आलेले शब्द ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "अक्का",
                "चप्पल ",
                "बांगडी",
                "किल्ली"
            ],
            "theme": "कानडी भाषेतून मराठीत आलेले शब्द ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "तीर",
                " पात्र",
                " वाळू",
                " घाट"
            ],
            "theme": "नदी संबंधित",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "वतन",
                " ठाणे",
                " दम",
                " दुकान"
            ],
            "theme": "____दार",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "अशोकस्तंभ",
                " गीर",
                " सिंबा",
                " छावा"
            ],
            "theme": "सिंह",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सिंघम",
                " खाकी",
                " चौकी",
                " गस्त"
            ],
            "theme": "पोलिस",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "गांधी",
                " रॉय",
                " बोस",
                " शाहरुख"
            ],
            "theme": "राहुल",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "मीना",
                " तेजस",
                " बाळ",
                " श्रीकांत"
            ],
            "theme": "ठाकरे",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "आदित्य",
                " मुकेश",
                " राहुल",
                " रतन"
            ],
            "theme": "उद्योग समूह प्रमुख",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "राज",
                " समाज",
                " अर्थ",
                " विना"
            ],
            "theme": "__ कारण",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "मीना",
                " तेजस",
                " बाळ",
                " श्रीकांत"
            ],
            "theme": "ठाकरे",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "लीला",
                " गरबा",
                " ढीग",
                " मेष"
            ],
            "theme": "रास",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "पूजा",
                "ऋषी",
                " दार",
                " दास"
            ],
            "theme": "देव ____",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "रेखा",
                " उर्मिला",
                " सौमित्र",
                " शेष"
            ],
            "theme": "लक्ष्मण",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "हात गाडी",
                "टोपली",
                "ठेला",
                "पथारी"
            ],
            "theme": "भाजी विक्रेत्यांची साधने",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "रुखवत ",
                "डाव",
                "हिशेब ",
                "पंगत"
            ],
            "theme": "मांडता येणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "पद्म",
                " वज्र",
                " हल",
                " शीर्ष"
            ],
            "theme": "___आसन",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "गदा",
                " त्रिशूल",
                " खड्ग",
                " परशू"
            ],
            "theme": "अस्त्रे",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "अंकुश",
                " पाश",
                " मोदक",
                " आशीर्वाद"
            ],
            "theme": "गणपती चे चार हात",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "खजूर",
                "उजाड ",
                "वाळू",
                "निवडुंग"
            ],
            "theme": "वाळवंटाची वैशिष्ट्य ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "दुबई ",
                "पॅरिस ",
                "टोकिओ ",
                "क्वालालंपूर"
            ],
            "theme": "प्रसिद्ध टॉवर असलेली शहरे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "भिंत",
                "प्रगती",
                "साम्यवाद",
                " अलीबाबा"
            ],
            "theme": "चीन",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "लामा",
                " मेटा",
                " जेमिनी",
                "जीपीटी"
            ],
            "theme": "एआय मॉडेल्स",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "नामंजूर",
                " मोर्चा",
                " दिवस",
                " आयुष्य"
            ],
            "theme": "संदीप खरे गाणी",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "लाल",
                " मृत",
                " अरब",
                " बंगाल"
            ],
            "theme": "समुद्र",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "दिल",
                " कस",
                " वजन",
                " भर"
            ],
            "theme": "___ दार",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "स्फूर्ती",
                " वेदना",
                " दिलासा",
                " आल्हाद"
            ],
            "theme": "____दायक",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "राज",
                " सिंह",
                " देव",
                " हरिश्चंद्र"
            ],
            "theme": "___ गड",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "वाघ",
                " उंदीर",
                " मोर",
                " ऐरावत"
            ],
            "theme": "देवांची वाहने",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "चंद्र",
                " गंगा",
                " साप",
                " नंदी"
            ],
            "theme": "शंकराशी संबंधित",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "पेटी",
                " मेंढी",
                " भुई",
                " कडे"
            ],
            "theme": "__ कोट",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 1
        },
        {
            "words": [
                "ढील",
                " शेपूट",
                "बेंजामिन फ्रँकलिन ",
                "मांजा"
            ],
            "theme": "पतंग",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "अंगठी",
                " बांगडी",
                " हातमोजा",
                " घड्याळ"
            ],
            "theme": "हातात घालायच्या वस्तू",
            "sharedBy": "रविकिरण देशपांडे",
            "difficulty": 0
        },
        {
            "words": [
                "हातमोजे",
                "चाकू",
                "प्रखर दिवे",
                "मास्क"
            ],
            "theme": "सर्जन",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "लाल",
                "मोहर",
                "पेटी",
                "सिल बंद"
            ],
            "theme": "लाख",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "नेम",
                "सहा",
                "गब्बर ",
                "कट्टा"
            ],
            "theme": "पिस्तूल",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "ऊब",
                "दुध",
                "झोप",
                "प्रेम"
            ],
            "theme": "तान्ह्या बळाला गरजेच्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "अग्नी",
                "पातेलं",
                "साखर",
                "हळद"
            ],
            "theme": "स्वयंपाकघरात अढळणार्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "नाग",
                "ऋषि",
                "रंग",
                "वसंत"
            ],
            "theme": "---पंचमी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "कट",
                "जवळ",
                "रण",
                "चीड"
            ],
            "theme": "मागोमाग दोनदा वापरता येणारे शब्द",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "शॉवर",
                "हत्ती",
                "कारंजे ",
                "अग्निशमन दल"
            ],
            "theme": "पाण्याचा फवारा मारणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सोडा",
                "टूथपेस्ट ",
                "समुद्राची लाट",
                "डिटर्जंट"
            ],
            "theme": "फेस येणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "मूर्ती",
                "समई ",
                "घंटा",
                "बिगुल"
            ],
            "theme": "पितळ्याच्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "वाटा",
                "आंब्याच्या फोडी",
                "खडी",
                "चिंध्या"
            ],
            "theme": "एखाद्या गोष्टीचे तुकडे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "माहीम ",
                "दुधी",
                "मुग",
                "बदामी"
            ],
            "theme": "हलवा",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "राजस्थान ",
                "इराण ",
                "ईजिप्त ",
                "ॲरिझोना"
            ],
            "theme": "वाळवंटी प्रदेश ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "प्रेम ",
                "विहीर ",
                "विचार",
                "संकट"
            ],
            "theme": "ज्यात पडता येतं",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "पाणी",
                "उपास",
                "गाठ",
                "बाण"
            ],
            "theme": "ज्या गोष्टी सोडता येतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "फांदी",
                "झेंडा",
                "केस",
                "ढग"
            ],
            "theme": "वार्‍याने हलणाऱ्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "धुर",
                "प्लास्टिक ",
                "आवाज",
                "औद्योगिक रसायने"
            ],
            "theme": "प्रदुषण कारक गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "औषध ",
                "विश्रांती",
                "मोसंबी ",
                "पांघरूण "
            ],
            "theme": "आजारी व्यक्तिला द्यायच्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "ओला कचरा",
                "पेट्री डिश",
                "ईडलीचे पीठ",
                "कोलन"
            ],
            "theme": "ज्यात बॅक्टेरिया अढळतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "बुबुळ",
                "उत्तप्पा",
                "डिव्हिडी",
                "फ्रिस्बी"
            ],
            "theme": "गोलाकार गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "गणपती",
                "क्वाडकाॅप्टर",
                "क्राॅस",
                "टेबल"
            ],
            "theme": "चार हात/पाय असलेले",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "कॅमेरा",
                "फोन ",
                "जी.पी.एस",
                "स्क्रिन"
            ],
            "theme": "स्मार्ट फोन ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "बुद्धी ",
                "दुर",
                "निर",
                "भुज"
            ],
            "theme": "---बळ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "धन",
                "आरोग्य ",
                "दीप ",
                "शत्रु"
            ],
            "theme": "शुभंकरोतीतले शब्द ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "वात",
                "गादी",
                "जांव्ह",
                "धोतर"
            ],
            "theme": "कापूसा पासून बनवलेल्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "वसंत",
                "अलंकार ",
                "प्रभात ",
                "आर्यन "
            ],
            "theme": "पुण्यातील पुर्वीची टाॅकिजे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "शरद",
                "अवरोह ",
                "संध्याकाळ",
                "घसरगुंडी"
            ],
            "theme": "ज्यात उतरत्या गोष्टी अढळतात (तापमान, सूर्य, सुर, इत्यादी)",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "वर्षा",
                "देश",
                "गणेश चतुर्थी",
                "धारा"
            ],
            "theme": "पावसाळ्या संबंधित (ऋतु, राग, सण, इत्यादी)",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "बुजगावणं",
                "पीक",
                "पाटाचं पाणी",
                "ट्रॅक्टर"
            ],
            "theme": "शेत",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "हिमालय",
                "तप",
                "विरक्ती",
                "लांब दाढी"
            ],
            "theme": "साधू",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "भाव",
                "भक्ती",
                "राष्ट्र",
                "अंगाई"
            ],
            "theme": "ज्यांना \"गीत\" जोडता येतं",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "नाणे",
                "दिवे",
                "कात्रज",
                "खंडाळा"
            ],
            "theme": "घाट",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "माती",
                "स्पंज",
                "वीट",
                "कापुस"
            ],
            "theme": "ज्याच्यात पाणी मुरते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "नवीन",
                "मलम",
                "सरस",
                "सामोसा"
            ],
            "theme": "Palindromes, of course! (विलोमपदॆ)",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 1
        },
        {
            "words": [
                "शारदा",
                "आरंभ",
                "चत्वार",
                "आनंत"
            ],
            "theme": "पहिल्या \"मनाचे श्लोका\"तील शब्द",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सिम",
                "रेशन",
                "आधार",
                "आय"
            ],
            "theme": "कार्ड",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "नदीचा प्रवाह",
                "घाट",
                "साप",
                "डबल्यु"
            ],
            "theme": "नागमोडी गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "बंब",
                "भस्म",
                "तेल",
                "लाकूड"
            ],
            "theme": "यांचा आगीशी खेळ!",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "लक्ष्मण",
                "फडणीस",
                "मिरांडा",
                "ठाकरे"
            ],
            "theme": "व्यंगचित्रकार",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "स्वप्नं",
                "कल्पना",
                "भूत",
                "सँताक्लॉज"
            ],
            "theme": "वास्तवात नसलेल्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "अणू",
                "फेस",
                "आकाशगंगा",
                "बबल रॅप"
            ],
            "theme": "ज्यात बहुतांशी पोकळी असते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "कांत",
                "संत",
                "गोळे",
                "सुर्वे"
            ],
            "theme": "काही मराठी साहित्यिकांची आडनावे",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "ज्योतिर्लिंग",
                "रास",
                "महिना",
                "इयत्ता"
            ],
            "theme": "बाराच्या संखेने असलेल्या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "रास्ता",
                "नवी",
                "कसबा",
                "रविवार"
            ],
            "theme": "पेठ",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "झोप",
                "खाद्य",
                "संगीत",
                "मद्य"
            ],
            "theme": "श्रमपरिहाराची साधने",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "मेधा",
                "स्मृति",
                "आठवण",
                "स्मरण"
            ],
            "theme": "मेमरी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "दशा",
                "व्यथा",
                "परिस्थिती",
                "अवस्था"
            ],
            "theme": "सिच्युएशन",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "मोहरी",
                "तेल",
                "हिंग",
                "हळद"
            ],
            "theme": "फोडणी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "कथा",
                "नचिकेत",
                "यम",
                "मोक्ष"
            ],
            "theme": "कठोपनिषद्",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "अभ्यास",
                "रियाज",
                "सराव",
                "मेहनत"
            ],
            "theme": "शिकणे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "सूर्य",
                "लेझर",
                "दीपस्तंभ",
                "ट्यूबलाईट"
            ],
            "theme": "प्रखर गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "निर्या",
                "जर",
                "घडी",
                "कांचीपुरम"
            ],
            "theme": "साडी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "सोनं",
                "मणि",
                "गोल",
                "बोट"
            ],
            "theme": "अंगठी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "ढीग",
                "कविता",
                "मनोरा",
                "लगोरी"
            ],
            "theme": "ज्या गोष्टी रचता येतात",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "बाळ",
                "प्रेक्षक",
                "चांभार",
                "पर्यटक"
            ],
            "theme": "ज्यांना कुतूहल असते",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "घनिष्ठ",
                "सांगत",
                "यार",
                "गट्टी"
            ],
            "theme": "मैत्री",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "वानर",
                "वेताळ",
                "वाघुळ",
                "टारझन"
            ],
            "theme": "झाडाला लोंबकळणाऱे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "गोबी",
                "थार",
                "कालाहरी",
                "मोहावी"
            ],
            "theme": "वाळवंटं",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "आरोह",
                "गिर्यारोहक",
                "मद्य",
                "ताप"
            ],
            "theme": "चढणार्‍या गोष्टी",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "अभिजित",
                "अमर्त्य",
                "रवींद्र",
                "कॆलाश"
            ],
            "theme": "भारतीय नोबेल विजेत्यांची पहिली नावे",
            "sharedBy": "केदार म्हसवडे",
            "difficulty": 0
        },
        {
            "words": [
                "गांधी",
                "फुले",
                "विदुर",
                "विश्वेश्वर"
            ],
            "theme": "\"महात्मा\" पदवी असलेले",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "बिड",
                "बिम",
                "बित",
                "बिर"
            ],
            "theme": "अर्थहीन पहिले, पण अर्थपूर्ण दुसरे शब्द (हाड-, काम-, हात-, घर-)",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "माध्यस्त",
                "पुल",
                "लग्न",
                "फोन कॉल"
            ],
            "theme": "दोन घटकांना जोडणारे",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "रक्त",
                "कुंकू",
                "जास्वंद",
                "थांबा"
            ],
            "theme": "लाल",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "वेफर्स ",
                "दाबेली ",
                "उकडलेला",
                "कंद"
            ],
            "theme": "बटाटा",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ],
    [
        {
            "words": [
                "भजी",
                "अक्कलेचा",
                "चिरलेला",
                "पात"
            ],
            "theme": "कांदा",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 0
        },
        {
            "words": [
                "कायदा",
                "ताल",
                "लग्गी",
                "ख्याल"
            ],
            "theme": "तबला",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 1
        },
        {
            "words": [
                "चौखूर धाव",
                "हयः",
                "तबेला",
                "झुपकेदार"
            ],
            "theme": "घोडा",
            "sharedBy": "संदीप सोवनी ",
            "difficulty": 2
        }
    ]
];